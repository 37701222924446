<template>
  <!-- 比赛考试作业 -->
  <div class="">
    <div class="box">
      <el-table
        v-loading="loading"
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        @current-change="handleCurrentChange"
        @row-click="rowClick"
        style="width: 100%"
      >
        <el-table-column
          property="date"
          align="center"
          label="状态"
          width="100"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.is_publish == 1">
              <div
                class="state"
                style="color: #28b58c"
                v-if="scope.row.activ_status == 1"
              >
                正在进行
              </div>
              <div
                class="state"
                style="color: #f15153"
                v-if="scope.row.activ_status == -2"
              >
                已结束
              </div>
              <div
                class="state"
                style="color: #909399"
                v-if="scope.row.activ_status == -1"
              >
                未开始
              </div>
            </div>
            <div v-if="scope.row.is_publish == 2">
              <div class="state" style="color: #2e89c7">未发布</div>
            </div>
            <div v-if="scope.row.is_publish == 3">
              <div class="state" style="color: #f15153">已删除</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          property="title"
          align="center"
          label="标题"
          show-overflow-tooltip

          width="220"
        >
        </el-table-column>

        <el-table-column align="center" label="类型" width="160">
           <template slot-scope="scope">
            <div class="type">
              <div class="type_left">
                <div :style="{ background: scope.row.type_color?scope.row.type_color:'#00957e' }">
                  {{ scope.row.type_str }}
                </div>
              </div>
              <div class="type_right" v-if="scope.row.match_title">
                <div
                  :style="{ background: scope.row.match_color ?scope.row.match_color:'#00957e'}"
                  v-if="scope.row.match_title"
                >
                  {{ scope.row.match_title }}
                </div>
                <!-- <div style="color: #000" >--</div> -->
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="question_num"
          align="center"
          label="题数"
          width="80"
        >
          <template slot-scope="scope">
            <div class="topicNum">{{ scope.row.question_num }}题</div>
          </template>
        </el-table-column>

        <el-table-column property="activ_time" align="center" label="时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #000" v-if="!scope.row.activ_time">--</div>

            <div class="time">{{ scope.row.activ_time }}</div>
          </template>
        </el-table-column>
        <el-table-column
          property="is_open_str"
          align="center"
          label="权限"
          width="80"
        >
        </el-table-column>
        <el-table-column
          property="group_name"
          align="center"
          label="举办者"
          width="100"
        >
        </el-table-column>

       

      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page" :style="$attrs.pageStyle || {}" v-if="total > 0">
      <el-pagination background layout="total,sizes,prev, pager, next" :total="total" @current-change="getTaskList" @size-change="onSizeChange" :page-size="pageSize" :current-page="page" >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { metask } from "@/api/me.js";
export default {
  props: {
    student_user_id:{
      default: () => 0,
    }
  },
  data() {
    //这里存放数据
    return {
      loading:false,
      page: 1,
      pageSize: 10,
      total:0,
      tableData: [],
    };
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
    this.getTaskList();
  },
  //方法集合
  methods: {
    // 我的比赛考试作业列表
    getTaskList(page) {
      this.page = page || 1;
      this.loading = true
      metask({ page: this.page, page_size: this.pageSize,student_user_id:this.student_user_id })
        .then(({ data }) => {
          this.tableData = data.data;
          this.total = data.total
        })
        .finally(() => {
            this.loading = false
          })
    },
    onSizeChange(pagesize){
      this.pageSize=pagesize
      this.getTaskList();
    },
    rowClick(e) {
      if((e.log_id || 0) > 0){
        this.$handleRoute({
          log_id: e.log_id,
          id:e.id,
        } , 'matchTiDanRecord')
      }else{
        this.$router.push({ name: "matchDetail"+e.type, query: { id: e.id} })
      }
    },

    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
  },
};
</script>
<style  lang='less' scoped>
.box {
  border: 1px solid #979797;
  .img {
    width: 20px;
    height: 20px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .source {
    height: 23px;
    border-radius: 2px;
    padding: 0 1rem;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 23px;
  }
  .type {
    display: flex;
    justify-content: space-around;
    .type_left {
      div {
        width: 49px;
        height: 23px;
        line-height: 23px;

        border-radius: 2px;
        padding: 0 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .type_right {
      width: 73px;
      height: 23px;
      // background: #ffdf3e;
      border-radius: 2px;
      div {
        height: 23px;
        line-height: 23px;
        border-radius: 2px;
        padding: 0 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .code {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .topicNum,
  .jurisdiction,
  .hold,
  .time {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #131313;
  }
}
</style>