import request from "@/Utils/api";
// 判断是否登录中
export function is_login(data){
    return request({
        url: "api/v1_0_0.user/is_login",
        method: "post",
        data,
    });
}
export function userInfo(data){
    return request({
        url: "api/v1_0_0.user/get_info",
        method: "post",
        data,
    });
}
export function userInfo2(data){
  return request({
      url: "api/v1_0_0.user/count",
      method: "post",
      data,
    });
}


// 我的课程
export function course(data){
  return request({
      url: "api/v2_0_0.course/my_course",
      method: "post",
      data,
    });
}
// 我的提单
export function form(data){
  return request({
      url: "api/v1_0_0.forum/types",
      method: "post",
      cache:true,
      data,
    });
}
// 我的题目
export function subject(data){
  return request({
      url: "api/v1_0_0.question",
      method: "post",
      data,
    });
}
// 题目分类
export function options_type(data){
  return request({
      url: "api/v1_0_0.question/options",
      method: "post",
      cache:true,
      data,
    });
}
// 题目列表
export function options_list(data){
  return request({
      url: "api/v1_0_0.question",
      method: "post",
      data,
    });
}
// 团队下的比赛、作业、考试

export function other_list(data){
  return request({
      url: "api/v1_0_0.user_group/other",
      method: "post",
      data,
    });
}
// 论坛列表
export function forum_list(data){
  return request({
      url: "api/v1_0_0.forum",
      method: "post",
      data,
    });
}
// 收藏
export function collect(data){
  return request({
      url: "api/v1_0_0.forum/collect",
      method: "post",
      data,
    });
}
// 点赞
export function praise(data){
  return request({
      url: "api/v1_0_0.forum/praise",
      method: "post",
      data,
    });
}

// 我的订单 
export function orders(data){
  return request({
      url: "api/v1_0_0.order",
      method: "post",
      data,
    });
}

//收藏课程
export function collect_list(data){
  return request({
      url: "api/v1_0_0.course/collect_list",
      method: "post",
      data,
    });
}

//收藏题目
export function question(data){
  return request({
      url: "api/v1_0_0.question/collect_list",
      method: "post",
      data,
    });

    
}
//收藏帖子
export function forum(data){
  return request({
      url: "api/v1_0_0.forum/collect_list",
      method: "post",
      data,
    });
}

// 我的比赛
export function task(data){
  return request({
      url: "api/v1_0_0.question_other",
      method: "post",
      data,
    });
}

export function metask(data){
  return request({
      url: "api/v1_0_0.question_other/my_list",
      method: "post",
      data,
    });
}


// 绑定个性域名
export function bindDomai(data){
  return request({
      url: "api/v1_0_0.user/bind_domain",
      method: "post",
      data,
    });
}

// 绑定邮箱
export function bindEmail(data){
  return request({
      url: "api/v1_0_0.user/bind_email",
      method: "post",
      data,
    });
}
// 绑定手机号
export function bindPhone(data){
  return request({
      url: "api/v1_0_0.user/bind_phone",
      method: "post",
      data,
    });
}

// 重置密码
export function resetPassword(data){
  return request({
      url: "api/v1_0_0.user/reset_password",
      method: "post",
      data,
    });
}
//题目删除
export function delQuestion(data){
  return request({
      url: "api/v1_0_0.question/remove",
      method: "post",
      data,
    });
}

// 题单删除
export function remove(data){
  return request({
      url: "api/v1_0_0.question_list/del",
      method: "post",
      data,
    });
}


// 我的收藏
export function meCollect(data){
  return request({
      url: "api/v1_0_0.user/collect",
      method: "post",
      data,
    });
}

// 我的反馈列表
export function feedback(data){
  return request({
      url: "api/v1_0_0.feedback",
      method: "post",
      data,
    });
}
// 我要反馈
export function meFeedback(data){
  return request({
      url: "api/v1_0_0.feedback/submit",
      method: "post",
      data,
    });
}
// 我的反馈详情
export function feedbackDetails(data){
  return request({
      url: "api/v1_0_0.feedback/info",
      method: "post",
      data,
    });
}
// 我的题单收藏
export function formCollect(data){
  return request({
      url: "api/v1_0_0.question_list/collect_list",
      method: "post",
      data,
    });
}
// 我的消息
export function message(data){
  return request({
      url: "api/v1_0_0.message",
      method: "post",
      data,
    });
}
// 系统公告
export function sysNotice(data){
  return request({
      url: "api/v1_0_0.forum/indexTop",
      method: "post",
      data,
    });
}

// 我的消息
export function update_info(data){
  return request({
      url: "api/v1_0_0.user/update_info",
      method: "post",
      data,
    });
}
// 申请记录
export function join_list(data){
  return request({
      url: "api/v1_0_0.user_group/join_list",
      method: "post",
      data,
    });
}
// 读取消息
export function read(data){
  return request({
      url: "api/v1_0_0.message/read",
      method: "post",
      data,
    });
}


// 是否加入
export function approval_join_apply(data){
  return request({
      url: "api/v1_0_0.user_group/approval_join_apply",
      method: "post",
      data,
    });
}

// 获取我未读的消息的数量
export function messageCount(data){
  return request({
      url: "api/v1_0_0.message/count",
      method: "post",
      data,
    });
}
// 我的题单
export function my_list(data){
  return request({
      url: "api/v1_0_0.question_list/my_list",
      method: "post",
      data,
    });
}
// 我的题目
export function my_question(data){
  return request({
      url: "api/v1_0_0.question/my_question",
      method: "post",
      data,
    });
}





















