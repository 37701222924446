import { render, staticRenderFns } from "./MeTask.vue?vue&type=template&id=520b660e&scoped=true&"
import script from "./MeTask.vue?vue&type=script&lang=js&"
export * from "./MeTask.vue?vue&type=script&lang=js&"
import style0 from "./MeTask.vue?vue&type=style&index=0&id=520b660e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "520b660e",
  null
  
)

export default component.exports